import { translateFunc } from "client/utils/translateFunc";
import { ArrowIcon } from "components/common/Icons/ArrowIcon";
import { Translate } from "components/common/Translate/Translate";
import { CompressionSettings } from "components/modules/Editors/Compressor/Components/CompressionSettings/CompressionSettings";
import { useSettings } from "./useSettings";

export const Settings = () => {
    const {
        handleMaxFileSizeChange,
        handleQualityChange,
        handleTypeChange,
        isMaxFileSizeDisabled,
        isOpen,
        isQualityDisabled,
        maxFileSize,
        quality,
        toggle,
        type,
    } = useSettings();

    return (
        <div>
            <div
                className="flex items-center py-2 px-2 font-semibold gap-4 cursor-pointer border dark:border-darkPrimary-600 bg-irBlue-100 dark:bg-darkPrimary-100 rounded-t-md"
                onClick={toggle}
            >
                <div>
                    <span className="capitalize">
                        <Translate keyName="compression-settings" />
                    </span>
                    {` (${translateFunc("common:optional")})`}
                </div>
                <div className="ml-auto">
                    {isOpen ? (
                        <div className="rotate-180">
                            <ArrowIcon width={15} height={15} />
                        </div>
                    ) : (
                        <div>
                            <ArrowIcon width={15} height={15} />
                        </div>
                    )}
                </div>
            </div>
            {isOpen && (
                <div className="border-[0.5px] border-[#2d2d2d24] dark:border-darkSurface-600 border-t-0 rounded-b-md">
                    <CompressionSettings
                        isMaxFileSizeDisabled={isMaxFileSizeDisabled}
                        type={type}
                        isQualityDisabled={isQualityDisabled}
                        handleTypeChange={handleTypeChange}
                        handleMaxFileSizeChange={handleMaxFileSizeChange}
                        handleQualityChange={handleQualityChange}
                        maxFileSize={maxFileSize}
                        quality={quality}
                    />
                </div>
            )}
        </div>
    );
};

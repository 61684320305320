import { extensionsConfig } from "client/config/extensionsConfig";
import { ReduxProvider } from "components/common/ReduxProvider/ReduxProvider";
import { ImageUploader } from "components/common/Uploader/ImageUploader/ImageUploader";
import { MAX_UPLOAD_LIMIT } from "../uploaderUtils";
import { Settings } from "./Settings/Settings";
import { useCompressUploader } from "./useCompressUploader";

const CompressUploaderContent = () => {
    const { deleteImage, deleteAllImages } = useCompressUploader();

    return (
        <ImageUploader
            deleteImage={deleteImage}
            deleteAllImages={deleteAllImages}
            maxUploads={MAX_UPLOAD_LIMIT}
            analytics={{
                pageKey: "image_compressor",
                pageTitle: "Image Compressor",
            }}
            settingsSlot={<Settings />}
            htmlFileInputId="compressor-file-input"
            supportedExtensions={extensionsConfig.compressor}
        />
    );
};

export const CompressUploader = () => {
    return (
        <ReduxProvider>
            <CompressUploaderContent />
        </ReduxProvider>
    );
};

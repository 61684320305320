import { useUploader } from "../useUploader";
import { setImages } from "store/features/compress/actions";
import { getImageExtension } from "client/utils/getImageExtension";
import { formatBytes } from "client/utils/formatBytes";
import { getRandomId } from "client/utils/getRandomId";
import { store, useAppDispatch } from "store/store";
import { ga } from "client/utils/adsense/ga";
import { gtag } from "client/utils/adsense/gtag";
import { useRouter } from "next/router";
import { getFCUrl } from "client/utils/getFCUrl";
import translatedRoutedSlugs, { type TranslatedRouteSlugs } from "translatedRouteSlugs";

export const useCompressUploader = () => {
    const dispatch = useAppDispatch();
    const router = useRouter();

    const handleProceed = () => {
        const task = images[0]?.task;
        if (!task) return;
        dispatch(
            setImages(
                images.map((image) => {
                    const ext = getImageExtension(image.file.file);
                    return {
                        id: getRandomId(),
                        original: {
                            height: 0,
                            width: 0,
                            size: formatBytes(image.task?.result?.size ?? 0),
                        },
                        preview: {
                            height: 0,
                            width: 0,
                            size: formatBytes(
                                image.task?.result?.size
                                    ? image.task.result.size ?? image.file?.size ?? image.file?.file.size ?? 0
                                    : 0,
                            ),
                        },
                        src: getFCUrl(
                            image.upload.type === "device"
                                ? image.file.src
                                : image.task?.result?.url
                                ? image.task?.result?.url.toString() ?? ""
                                : "",
                        ),
                        title: image.file?.file.name ?? "",
                        format: image.file ? getImageExtension(image.file.file) : ".jpg",
                        createdAt: new Date(),
                        quality: store.getState().compress.compressionSettings.quality.value,
                        sliderValue: store.getState().compress.compressionSettings.quality.value,
                        importTask: image.task,
                        state: "imported",
                        hasExpired: false,
                        file: image.file,
                        uploadType: image.upload.type,
                        settings: image.upload.settings,
                        compressionSettings: {
                            ...store.getState().compress.compressionSettings,
                            type: ext === "png" ? "quality" : store.getState().compress.compressionSettings.type,
                        },
                        ext,
                    };
                }),
            ),
        );

        router.push(
            translatedRoutedSlugs["/image-compressor/editor"]?.[
                (router.locale as keyof TranslatedRouteSlugs["/image-compressor/editor"]) ?? "en"
            ] ?? "/image-compressor/editor",
        );
    };

    const handleFirstImageLoadedAnalytics = () => {
        ga("send", {
            hitType: "pageview",
            page: "/vp/image_compressor/image_loaded/",
            title: "Image Compressor Image Loaded",
        });

        ///event
        gtag("event", "Image Loaded", {
            event_category: "Image Compressor",
            event_label: "Upload Page",
        });
    };

    const { images, deleteImage, deleteAllImages } = useUploader(handleProceed, handleFirstImageLoadedAnalytics);

    return { deleteImage, deleteAllImages };
};
